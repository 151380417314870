export const marketStatus = [
  {
    code: 'Open',
    icon: 'fas fa-circle',
    textColor: 'text-success',
    borderClass: 'success',
    text: 'เปิดแทง'
  },
  {
    code: 'Close',
    icon: 'fas fa-ban',
    textColor: 'text-danger',
    borderClass: 'danger',
    text: 'ปิดแทง'
  },
  {
    code: 'Delete',
    icon: 'fas fa-trash-alt',
    textColor: 'text-secondary',
    borderClass: 'secondary',
    text: 'ลบแล้ว'
  }
]

export const daysOfWeek = [
  {
    code: 'Mon',
    text: 'จันทร์'
  },
  {
    code: 'Tue',
    text: 'อังคาร'
  },
  {
    code: 'Wed',
    text: 'พุธ',
  },
  {
    code: 'Thu',
    text: 'พฤหัสบดี'
  },
  {
    code: 'Fri',
    text: 'ศุกร์'
  },
  {
    code: 'Sat',
    text: 'เสาร์'
  },
  {
    code: 'Sun',
    text: 'อาทิตย์'
  }
]

export const marketOpenBets = [
  {
    code: 'threeNumberTop',
    text: '3 ตัวบน'
  },
  {
    code: 'threeNumberTode',
    text: '3 ตัวโต๊ด'
  },
  {
    code: 'twoNumberTop',
    text: '2 ตัวบน'
  },
  {
    code: 'twoNumberBottom',
    text: '2 ตัวล่าง'
  },
  {
    code: 'runTop',
    text: 'วิ่งบน'
  },
  {
    code: 'runBottom',
    text: 'วิ่งล่าง'
  }
]

export const marketTypes = [
  {
    code: 'single',
    set: 'single',
    text: 'หวยเดี่ยว',
    openBets: marketOpenBets
  },
  {
    code: 'lottoset',
    text: 'หวยชุด',
    sets: [{
      set: 'set4',
      text: 'หวยชุด 4 ตัว',
      openBets: [
        {
          code: 'fourNumber',
          text: '4 ตัวตรง'
        },
        {
          code: 'threeNumber',
          text: '3 ตัวตรง'
        },
        {
          code: 'fourNumberTode',
          text: '4 ตัวโต๊ด'
        },
        {
          code: 'threeNumberTode',
          text: '3 ตัวโต๊ด'
        },
        {
          code: 'twoNumberPrefix',
          text: '2 ตัวหน้า'
        },
        {
          code: 'twoNumberSuffix',
          text: '2 ตัวหลัง'
        }
      ]
    }, {
      set: 'set5',
      text: 'หวยชุด 5 ตัว',
      openBets: [
        {
          code: 'fiveNumber',
          text: '5 ตัวตรง'
        },
        {
          code: 'fourNumber',
          text: '4 ตัวตรง'
        },
        {
          code: 'threeNumber',
          text: '3 ตัวตรง'
        },
        {
          code: 'fourNumberTode',
          text: '4 ตัวโต๊ด'
        },
        {
          code: 'threeNumberTode',
          text: '3 ตัวโต๊ด'
        },
        {
          code: 'twoNumberPrefix',
          text: '2 ตัวหน้า'
        },
        {
          code: 'twoNumberSuffix',
          text: '2 ตัวหลัง'
        }
      ]
    }]
  }
]

export const groupTypes = [
{
    code: 'single',
    text: 'หวยเดี่ยว'
  },
  {
    code: 'lottoset',
    text: 'หวยชุด'
  }
]
