export const LottoSetsOpenBetsInterface = {
  fourNumber: {
    isAvailable: false,
    pay: 0
  },
  fourNumberTode: {
    isAvailable: false,
    pay: 0
  },
  threeNumber: {
    isAvailable: false,
    pay: 0
  },
  threeNumberTode: {
    isAvailable: false,
    pay: 0
  },
  twoNumberPrefix: {
    isAvailable: false,
    pay: 0
  },
  twoNumberSuffix: {
    isAvailable: false,
    pay: 0
  }
}

export const LottoSetsRateInterface = {
  marketSet: 'set4',
  rateTitle: '',
  price: 0,
  discount: 0,
  sort: 0,
  status: 'Active',
  openBets: LottoSetsOpenBetsInterface
}
