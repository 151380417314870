<template>
  <div class="card border-info">
    <div class="card-head d-flex justify-content-between alert-info">
      <div class="d-flex align-items-center">
        <h5 class="card-title my-3 ml-3 p-0">{{ title }}</h5>
        <span class="ml-2" :class="statusClass">({{ statusText }})</span>
      </div>
      <button class="btn btn-link btn-sm mr-2" @click="edit"><i class="fas fa-edit"></i></button>
    </div>
    <div class="card-body p-0">
      <div class="p-2">
        <div class="px-2 d-flex justify-content-between">
          <span>สำหรับ:</span>
          <span class="text-primary">{{marketSets}}</span>
        </div>
        <div class="px-2 d-flex justify-content-between">
          <span>ราคา:</span>
          <span class="text-primary">{{price | amountFormat(2, '0.00')}}</span>
        </div>
        <div class="px-2 d-flex justify-content-between">
          <span>ส่วนลด(%):</span>
          <span class="text-primary">{{discount | amountFormat(2, '0.00')}}</span>
        </div>
      </div>
      <table class="table table-sm mb-0">
        <thead>
          <tr>
            <th style="font-size: 90%; font-weight: normal;">รางวัล</th>
            <th width="30%" style="font-size: 90%; font-weight: normal;" class="text-center">จ่าย</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="bet in openBets"
            :key="bet.code"
            v-if="prizes[bet.code].isAvailable"
            class="text-success"
            >
            <td>{{ bet.text }}</td>
            <td class="text-right">{{ prizes[bet.code].pay | amountFormat(2, '0.00') }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { marketTypes } from '@/configs/market.config'

export default {
  name: 'SetsRateCard',
  props: {
    data: {
      type: [Object, String],
      default: null
    }
  },
  computed: {
    title() {
      if(this.data){
        return this.data.rateTitle
      }else{
        return '-'
      }
    },
    statusText() {
      if(this.data){
        return this.data?.status==='Active'?'ใช้งาน':'ไม่ใช้งาน'
      }else{
        return '-'
      }
    },
    marketSets() {
      const types = marketTypes.find((t)=>{
        return t.code === 'lottoset'
      })

      const sets = (types?.sets||[]).find((t)=>{
        return t.set === this.data.marketSet
      })

      return sets?.text
    },
    price() {
      return this.data.price ?? 0
    },
    discount() {
      return this.data.discount ?? 0
    },
    openBets() {
      const types = marketTypes.find((t)=>{
        return t.code === 'lottoset'
      })

      const setsInfo = (types?.sets||[]).find((s)=>{
        return s.set === this.data.marketSet
      })

      const openBets = setsInfo?.openBets || []

      return openBets
    },
    prizes() {
      const defaultData = this.openBets.reduce((configs, bet)=>{
        configs[bet.code] = {
          isAvailable: false,
          pay: 0
        }
        return configs
      }, {})

      return {
        ...defaultData,
        ...this.data.openBets
      }
    },
    statusClass() {
      if(this.data){
        switch(this.data.status) {
          case 'Active':
            return 'text-success'
          break

          case 'Cancel':
            return 'text-danger'
          break
        }
      }else{
        return '-'
      }
    }
  },
  methods: {
    edit() {
      this.$emit('edit', this.data)
    },
    // betOption(code, param) {
    //   if(this.data) {
    //     return this.data.openBets[code][param]
    //   }else{
    //     return null
    //   }
    // },
    // betOptionAvailable(code) {
    //   if(this.data) {
    //     return this.data.openBets[code].isAvailable
    //   }else{
    //     return false
    //   }
    // }
  }
}
</script>
