<template>
  <b-modal
    ref="lotto-sets-rate-modal"
    hide-footer
    title="อัตราจ่ายหวยชุด"
    ok-variant="success"
    @show="setData"
    @hidden="hideModal"
  >
    <form
      id="market-set-form"
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >

      <b-form-group
        label="สถานะ"
        v-slot="{ ariaDescribedby }"
        label-class="text-primary"
      >
        <b-form-radio-group
          id="radio-status"
          v-model="input.status"
          :options="statusOptions"
          :aria-describedby="ariaDescribedby"
          name="radio-options"
        ></b-form-radio-group>
      </b-form-group>

      <b-form-group
        v-if="setOptions.length"
        id="setting-lotto-sets"
        label="หวยชุด"
        label-class="text-primary"
        v-slot="{ ariaDescribedby }"
      >
        <b-form-radio-group
          id="radio-lotto-sets"
          v-model="input.marketSet"
          :options="setOptions"
          :aria-describedby="ariaDescribedby"
          name="markets-sets"
        ></b-form-radio-group>
      </b-form-group>

      <div class="row">
        <div class="col-8">
          <b-form-group
            label="ชื่ออัตราจ่าย"
            label-for="input-payrate-title"
          >
            <b-form-input
              id="input-payrate-title"
              v-model="input.rateTitle"
              type="text"
              placeholder="ชื่ออัตราจ่าย"
              required
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-4">
          <b-form-group
            label="เรียงลำดับ"
            label-for="input-payrate-sort"
          >
            <b-form-input
              id="input-payrate-sort"
              v-model="input.sort"
              type="text"
              placeholder="เรียงลำดับ"
              :number="true"
              required
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <b-form-group
            label="ราคา"
          >
            <b-form-input
              type="text"
              v-model="input.price"
              placeholder="ราคาต่อชุด"
              :number="true"
              required
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group
            label="ส่วนลด (%)"
          >
            <b-form-input
              type="text"
              v-model="input.discount"
              placeholder="% ส่วนลดต่อชุด"
              :number="true"
              required
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <b-form-group
        label="รางวัล"
        label-class="text-primary"
        v-slot="{ ariaDescribedby }"
      >
        <table class="table-payrate table table-sm">
          <thead>
            <tr>
              <th width="70%">เลข</th>
              <th width="30%">จ่าย</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="bet in openBetsOptions" :key="bet.code">
              <td>
                <b-form-checkbox v-model="input.openBets[bet.code].isAvailable" :value="true">{{ bet.text }}</b-form-checkbox>
              </td>
              <td>
                <b-form-input size="sm" v-model="input.openBets[bet.code].pay" :disabled="!input.openBets[bet.code].isAvailable" type="number" :number="true"></b-form-input>
              </td>
            </tr>
          </tbody>
        </table>
      </b-form-group>

      <div class="d-flex justify-content-end mt-3 pt-3">
        <b-button variant="link" size="sm" class="text-secondary mr-3" @click="hideModal">ยกเลิก</b-button>
        <b-button type="submit" variant="primary" size="sm" :disabled="isProcess">{{isProcess ? 'กำลังบันทึก...' : 'บันทึก'}}</b-button>
      </div>
    </form>
  </b-modal>
</template>
<script>
import Swal from 'sweetalert2'
import SetsService from '@/services/setsService'
import { marketTypes } from '@/configs/market.config'
import { LottoSetsRateInterface } from '@/interfaces/LottoSets'

export default {
  name: 'SetsRateModal',
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: null
    },
    data: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      needReload: false,
      isProcess: false,
      input: LottoSetsRateInterface
    }
  },
  computed: {
    statusOptions() {
      return [
        { text: 'ใช้งาน', value: 'Active' },
        { text: 'ไม่ใช้งาน', value: 'Inactive' }
      ]
    },
    setOptions() {
      const types = marketTypes.find((t)=>{
        return t.code === 'lottoset'
      })

      return (types?.sets||[]).map((s)=>{
        return {
          text: s.text,
          value: s.set
        }
      })
    },
    openBetsOptions() {
      const types = marketTypes.find((t)=>{
        return t.code === 'lottoset'
      })

      const setsInfo = (types?.sets||[]).find((s)=>{
        return s.set === this.input.marketSet
      })

      const openBets = setsInfo?.openBets || []

      const defaultData = openBets.reduce((configs, bet)=>{
        configs[bet.code] = {
          isAvailable: false,
          pay: 0
        }
        return configs
      }, {})

      this.input.openBets = {
        ...defaultData,
        ...this.input.openBets
      }

      return openBets
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.showModal()
      }else{
        this.hideModal()
      }
    }
  },
  methods: {
    setData() {
      this.needReload = false
      this.input = JSON.parse(JSON.stringify(this.data))
    },
    showModal() {
      this.$refs['lotto-sets-rate-modal'].show()
    },
    hideModal() {
      this.$refs['lotto-sets-rate-modal'].hide()
    },
    handleSubmit() {
      const openBets = this.openBetsOptions.reduce((bets, o)=>{
        bets[o.code] = this.input.openBets[o.code]
        return bets
      }, {})

      const setData = {
        ...this.input,
        openBets: openBets
      }

      if(this.id) {
        this.updateData(this.id, setData)
      }else{
        this.saveData(setData)
      }
    },
    saveData(data) {
      this.isProcess = true
      SetsService.saveSetsRate(data)
      .then((response)=>{
        if(response.success) {
          this.needReload = true
          this.hideModal()
        }else{
          throw new Error(response?.message)
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    },
    updateData(id, data) {
      this.isProcess = true
      SetsService.updateSetsRate(id, data)
      .then((response)=>{
        if(response.success) {
          this.needReload = true
          this.hideModal()
        }else{
          throw new Error(response?.message)
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: 'บันทึกข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isProcess = false
      })
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close', this.needReload)
    })
  }
}
</script>
<style lang="scss" scoped>
.table {
  tr {
    td {
      vertical-align: middle;
    }
  }
}
</style>
