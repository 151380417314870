<template>
  <div class="container">
    <div class="page-header d-flex justify-content-between align-items-center mb-1">
      <strong class="page-title">อัตราจ่ายหวยชุด</strong>
      <button class="add-setsrate-btn btn btn-sm btn-link" @click="addRate">เพิ่มอัตราจ่าย</button>
    </div>

    <div class="row">
      <div class="col-md-4" v-for="item in items">
        <SetsRateCard :data="item" @edit="editRate" />
      </div>
    </div>

    <SetsRateModal :id="rateId" :data="setsRate" :is-show="isShowModal" @close="modalClosed" />
  </div>
</template>
<script>
import moment from '@/services/moment'
import SetsService from '@/services/setsService'
import { LottoSetsRateInterface } from '@/interfaces/LottoSets'
import SetsRateCard from './components/SetsRateCard'
import SetsRateModal from './components/SetsRateModal'

export default {
  name: 'LottoSetsRate',
  components: {
    SetsRateModal,
    SetsRateCard
  },
  data() {
    return {
      rateId: null,
      data: [],
      setsRate: LottoSetsRateInterface,
      isShowModal: false
    }
  },
  computed: {
    items() {
      return this.data || []
    }
  },
  methods: {
    addRate() {
      this.setsRate = {
        ...LottoSetsRateInterface,
        timestamp: moment().format('x')
      }
      this.isShowModal = true
    },
    editRate(rate) {
      this.rateId = rate._id
      this.setsRate = {
        marketSet: rate.marketSet,
        rateTitle: rate.rateTitle,
        price: rate.price,
        discount: rate.discount,
        sort: rate.sort,
        status: rate.status,
        openBets: rate.openBets,
        timestamp: moment().format('x')
      }
      this.isShowModal = true
    },
    loadData() {
      SetsService.getSetsRate().then((response)=>{
        if(response.success) {
          this.data = response.data
        }
      })
    },
    modalClosed(needReload) {
      this.rateId = null
      this.setsRate = JSON.parse(JSON.stringify(LottoSetsRateInterface))
      this.isShowModal = false
      if(needReload) {
        this.loadData()
      }
    }
  },
  mounted() {
    this.loadData()
  }
}
</script>
